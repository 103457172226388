export const theme = {

  // ===========================
  // カラー
  // ===========================
  // メインカラー
  color_main: '#94c5dc',

  // サブカラー
  color_sub: '#5d6262',

  // アクセントカラー
  color_accent: '#b2914e',

  // リンクカラー
  color_link: '#94c5dc',

  // 背景色
  color_back: '#E7EEF7',

  // 全体で使用する文字色（メイン）
  color_main_text: '#6683a7',

  // 全体で使用する文字色（サブ）
  color_sub_text: '#5d6262',

  // ===========================
  // 幅
  // ===========================
  // 全体幅
  base_content_width: '990px',

  // ===========================
  // ブレイクポイント
  // ===========================
  // 601px未満（sp上限）
  sp_max_width: '600.98px',

  // 601px以上（tb下限）
  tb_min_width: '601px',

  // 1025px未満（tb上限）
  tb_max_width: '1024.98px',

  // 1025px以上（pc下限）
  pc_min_width: '1025px',

  // ===========================
  // フォントファミリー
  // ===========================
  //ゴシック系
  sans_serif_font: '-apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif',

  //明朝系
  serif_font: '"A1明朝", "A1 Mincho", "リュウミン M-KL", "Ryumin Medium KL", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif',
}
