import styled from 'styled-components'

export const StyledBurger = styled.button`
  position: fixed;
  top: 20px;
  right: 20px;
  width: 54px;
  height: 40px;
  display: block;
  cursor: pointer;
  z-index: 98;
  border: 1px solid ${({ theme }) => theme.color_main_text};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color_back};

  &:focus {
    outline:0;
  }

  span {
    position: absolute;
    background: ${({ theme }) => theme.color_main_text};
    width: 36px;
    height: 2px;
    transition: all 1s ease-in-out;

    :first-child {
      top: 8px;
      left: 9px;
      transform: ${({ open }) => open ? 'translateX(0px) translateY(10px) rotate(-45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      top: 18px;
      left: 9px;
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(0)'};
    }

    :nth-child(3) {
      top: 28px;
      left: 9px;
      transform: ${({ open }) => open ? 'translateX(0px) translateY(-10px) rotate(45deg)' : 'rotate(0)'};
    }
  }
`
