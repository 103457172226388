import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import '../../scss/main.scss'
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';

//Font AwesomeのCSSを先読みする設定
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

export default ({ children }) => (
    <ThemeProvider theme={theme}>
        <div className="wrapper">
            <Header />
            {children}
            <Footer />
        </div>
    </ThemeProvider>
)