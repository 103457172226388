import React from 'react'

export default () => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className="Footer">
      <div className="Footer__foot">
        <div className="Footer__logo">
          <p className="Footer__logo--text">
            有限会社 スタジオ・アオ
        </p>
        </div>
        <div className="Footer__copyRight">
          <small>© {year} Studio Ao.</small>
        </div>
        <div className="Footer__address">
          〒460-0008
        <br />愛知県名古屋市中区栄1-14-15
        <br />RSビル801
      </div>
        <ul className="Footer__detail">
          <li>
            <svg id="文字" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.5 50.4"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#6683a7}" }} /><path className="st0" d="M0 0v14.1h3.1v-11h45.3v11h3.1V0zm0 21.8v28.6h51.5V21.8H0zm48.4 25.5H3.1V24.9h45.2v22.4z" /><path className="st0" d="M15.1 32c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3z" /><circle className="st0" cx="21.2" cy={32} r={3} /><path className="st0" d="M33.2 32c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.3 3 3zm9.1 0c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3 1.6 0 3 1.3 3 3zm-27.2 8.7c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.4 3 3z" /><circle className="st0" cx="21.2" cy="40.7" r={3} /><path className="st0" d="M33.2 40.7c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3s3 1.4 3 3zm9.1 0c0 1.6-1.3 3-3 3-1.6 0-3-1.3-3-3 0-1.6 1.3-3 3-3 1.6 0 3 1.4 3 3z" /></svg>
          052-938-9021
        </li>
          <li>
            <svg id="文字" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.5 50.4"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#6683a7}" }} /><path className="st0" d="M9.1 12.9h32.4V16H9.1zm0 10.8h32.4v3.1H9.1z" /><path className="st0" d="M0 0v50.4h3.1v-.1c14.2-.4 19.9-3.1 25.3-5.9 5.2-2.6 10.1-5.1 23.1-5.1V0H0zm48.4 36.2c-11.4.4-16.6 2.9-21.4 5.4-5.1 2.6-10.4 5.2-23.8 5.5v-44h45.3v33.1z" /></svg>
          052-938-9022
        </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.5 50.3"><path d="M0 0v50.3h51.5V0H0zm48.4 3.1v10.5C44.7 15.6 27 25 25.7 25.7c-1.3-.7-19-10.1-22.6-12V3.1h45.3zM3.1 47.2V17.3l22.6 12 22.6-12v29.9H3.1z" fill="#6683a7" /></svg>
          info＠studio-ao.co.jp
        </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.7 50.2"><path d="M46 30.1V0H6.7v30.1L0 50.2h52.7L46 30.1zM9.9 3.1h33v27h-33v-27zM9 33.3h34.6C44.2 35 47.3 44 48.3 47H4.5c1-3 4-12 4.5-13.7z" fill="#6683a7" /></svg>
          https://studio-ao.co.jp
        </li>
        </ul>
      </div>
    </footer>
  )
}