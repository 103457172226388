import React, { useState, useRef } from 'react'
import { useOnClickOutside } from '../hooks/hooks';
import Burger from '../burger/burger';
import Menu from '../menu/menu';
import FocusLock from 'react-focus-lock';

export default () => {
    const [open, setOpen] = useState(false)
	const node = useRef()
	const menuId = "main-menu"

    useOnClickOutside(node, () => setOpen(false))
    
    return (
		<>
			<div ref={node}>
				<FocusLock disabled={!open}>
					<Burger open={open} setOpen={setOpen} aria-controls={menuId} />
					<Menu open={open} setOpen={setOpen} id={menuId} />
				</FocusLock>
			</div>
		</>
	)
}