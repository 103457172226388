import React from 'react'
import { bool } from 'prop-types'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StyledMenu } from './menu.styled'

const Menu = ({ open, ...props }) => {

  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <header class="Header">
      <nav>
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
          <ul>
            <li>
              {/*
                <fade>
                to        : 遷移先
                duration  : アニメーションの時間
              */}
              <AniLink
                fade
                to={`/`}
                duration={1}
                tabIndex={tabIndex}
              >ホーム</AniLink>
            </li>
            <li>
              {/*
                <fade>
                to        : 遷移先
                duration  : アニメーションの時間
              */}
              <AniLink
                fade
                to={`/vision/`}
                duration={1}
                tabIndex={tabIndex}
              >ヴィジョン</AniLink>
            </li>
            <li>
              {/*
                <cover>
                to        : 遷移先
                duration  : アニメーションの時間
                bg        : 色
                direction : カバー要素の方向
                            up,down,left,right を指定
              */}
              <AniLink
                cover
                to={`/blog/`}
                duration={1}
                bg="#6683a7"
                direction="left"
                tabIndex={tabIndex}
              >ブログ</AniLink>
            </li>
            <li>
              <a href="https://division6.jp/invader/" target="_blank" rel="noopener noreferrer">おまけ</a>
            </li>
          </ul>
        </StyledMenu>
      </nav>
    </header>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
