import styled from 'styled-components'

export const StyledMenu = styled.div`
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
  text-align: center;
  padding: 2rem;
  padding-top: 80px;
  position: fixed;
  top: 0;
  left: 0;
  background: #EEE;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: 100vh;
  z-index: 97;

  ul {
    background-color: #fff;
  }

  li {
    position: relative;
    font-size: .9rem;
    padding: 10px 0 10px 10px;
    border-bottom: 1px solid #ccc;
    @media (max-width: ${({ theme }) => theme.sp_max_width}) {
      font-size: 0.8rem;
    }
  }

  a {
    display: block;
    color: #777;
    line-height: 40px;
    text-decoration: none;
    padding: 0;
    &:hover {
      color: $color-link;
      text-decoration: underline;
    }
    span {
      font-size: 80%;
    }
  }
`
